import VidButton from "./Components/VidButton";
import VidWrapper from "./Components/VidWrapper";
import VidLink from "./Components/VidLink";
import { render, Fragment } from "preact";
import { useState, useRef } from "preact/hooks";
import clsx from "clsx";

function VideoPickerComponent({ data }) {
  const [step, setStep] = useState(0);
  const [vidStep, setVidStep] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(100);
  const vidRef = useRef(null);
  const [fade, setFade] = useState(false);

  const vidUrl = data[step]?.video?.[0]?.url || "";

  const reset = () => {
    setCurrentTime(0);
    setStep(0);
    vidRef.current.currentTime = 0;
  };

  const onEnded = (e) => {
    if (step < data.length - 1) {
      setStep(step + 1);
    } else {
      setStep(0);
    }
  };

  let style = "";
  switch (step % 5) {
    case 0:
    case 5:
      style += "color: #3F3D56 !important;";
      break;
    case 1:
      style += "color: #F96209 !important;";
      break;
    case 2:
      style += "color: #18AEAD !important;";
      break;
    case 3:
      style += "color: #20A9E9 !important;";
      break;
    case 4:
      style += "color: #F6BD02 !important;";
      break;
    default:
      break;
  }

  const onTimeupdate = (e) => {
    setCurrentTime(e.target.currentTime);
    setDuration(e.target.duration);
    e.target.playbackRate = 1.5;
    if (e.target.currentTime > 0.25) {
      setFade(false);
    }
    if (e.target.currentTime > e.target.duration - 0.25) {
      setFade(true);
    }
  };

  const handleBulletClick = (bullet) => {
    const timestamp = +bullet.timestamp;
    vidRef.current.currentTime = timestamp;
  };

  return (
    <>
      <aside class=" hidden lg:flex justify-center mt-10 lg:mt-20">
        {data.map((btn, i) => (
          <VidButton
            className="mx-2 max-w-[186px]"
            key={i}
            i={i}
            active={step === i}
            icon={btn.icon}
            onClick={(e) => {
              setStep(i);
              setVidStep(0);
              setCurrentTime(0);
              vidRef.current.currentTime = 0;
            }}
          >
            {btn.heading}
          </VidButton>
        ))}
      </aside>
      <div class="lg:flex flex-wrap max-w-[1000px] m-auto xl:mt-10 mb-3 lg:mt-10 lg:mb-10 relative">
        <div class="filter mb-2 lg:hidden">
          <div class="flex">
            <details class="w-full bg-purple px-3 rounded-md text-purple-light mt-10">
              <summary class="relative text-[14px] w-full py-0 flex items-center font-bold h3">
                Filter - {data[step].heading}
                <p class="accord-arrows text-[14px] normal-case"></p>
              </summary>
              {data.map((btn, i) => (
                <button
                  onClick={(e) => {
                    setStep(i);
                    setVidStep(0);
                    setCurrentTime(0);
                    vidRef.current.currentTime = 0;
                  }}
                  key={i}
                  class={clsx(
                    "flex w-full map-dot rounded-md border-purple-light p-2 nav-left mb-[2px] 2xl:mb-1 border items-center cursor-pointer ",
                    step === i && "active"
                  )}
                >
                  {btn.icon && (
                    <img
                      src={btn.icon}
                      class="2xl:w-5 w-3 h-3 2xl:h-5 object-contain"
                    />
                  )}
                  <div class="ml-3 transition-all duration-100 rounded-full map-nav-btn grid min-h-[30px]">
                    <p class="mb-0 text-[13px] 2xl:text-[14px] font-bold text-left">
                      {btn.heading}
                    </p>
                  </div>
                </button>
              ))}
            </details>
          </div>
        </div>
        <VidWrapper step={step}>
          <video
            playsinline
            id="myVideo"
            preload="none"
            ref={vidRef}
            src={vidUrl}
            class={clsx(
              "w-full h-full bg-bg absolute left-0 top-0 transition-all duration-75",
              fade && "opacity-0"
            )}
            muted
            autoplay
            controls
            onTimeupdate={onTimeupdate}
            onEnded={onEnded}
          >
            Your browser does not support the video tag.
          </video>
        </VidWrapper>
        <aside class="relative h-full mt-10 z-10 w-full pr-5 self-center">
          <div class="flex mt-2 md:mt-0 relative w-full">
            <h3
              class=" font-semibold mb-2 md:mb-5 flex items-center"
              style={style}
            >
              {data[step].icon && (
                <img
                  class="w-[30px] md:w-[2em] mr-2"
                  src={data[step].icon}
                  alt={data[step].heading}
                />
              )}
              <span class="flex-1">{data[step].heading}</span>
            </h3>
            <button
              onClick={reset}
              class="map-dot border-purple absolute right-0 top-0 md:hidden mt-3 md:mt-0 rounded-full w-[45px] md:w-[150px] m-auto h-[45px] bg-purple-dark hover:bg-purple-light hover:text-purple duration-100 transition-all hover:border-purple border text-purple-light place-items-center justify-center md:rounded-md"
            >
              <svg
                class="m-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="24.768"
                height="24.128"
                viewbox="0 0 24.768 24.128"
              >
                <defs>
                  <clipPath id="clip-path">
                    <rect
                      id="Rectangle_184"
                      data-name="Rectangle 184"
                      width="24.768"
                      height="24.128"
                      fill="#8a86a9"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Group_247"
                  data-name="Group 247"
                  clip-path="url(#clip-path)"
                >
                  <path
                    id="Path_616"
                    data-name="Path 616"
                    d="M22.325,4.984a12.38,12.38,0,0,1-.992,15.942l2.44-.117.152,2.915-7.677.4-.4-7.614,2.915-.151.15,2.679a9.385,9.385,0,0,0,2.737-7.461,9.323,9.323,0,0,0-18.568,1.6A9.351,9.351,0,0,0,8.42,20.817l.158.073-1.3,2.778-.155-.075A12.382,12.382,0,1,1,22.321,5Z"
                    transform="translate(0 0)"
                    fill="#8a86a9"
                  />
                </g>
              </svg>
            </button>
          </div>
          <div class="flex-wrap gap-2 grid-cols-4 2xl:gap-3 flex lg:grid slider2-nav mt-2">
            {data[step]?.video[0]?.bullets?.map((bullet, i) => (
              <VidLink
                key={i}
                active={currentTime > +bullet.timestamp}
                onClick={() => handleBulletClick(bullet)}
              >
                {bullet.heading}
              </VidLink>
            ))}
          </div>
        </aside>
      </div>
      <button
        onClick={reset}
        class="map-dot hidden border-purple mt-2 md:mt-0 rounded-full w-[45px] md:w-[150px] m-auto h-[45px] bg-purple-dark hover:bg-purple-light hover:text-purple duration-100 transition-all hover:border-purple border text-purple-light md:flex place-items-center justify-center md:rounded-md"
      >
        <p class="mr-3 hidden md:block mb-0 text-[#8A86A9]">Restart</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24.768"
          height="24.128"
          viewbox="0 0 24.768 24.128"
        >
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_184"
                data-name="Rectangle 184"
                width="24.768"
                height="24.128"
                fill="#8a86a9"
              />
            </clipPath>
          </defs>
          <g id="Group_247" data-name="Group 247" clip-path="url(#clip-path)">
            <path
              id="Path_616"
              data-name="Path 616"
              d="M22.325,4.984a12.38,12.38,0,0,1-.992,15.942l2.44-.117.152,2.915-7.677.4-.4-7.614,2.915-.151.15,2.679a9.385,9.385,0,0,0,2.737-7.461,9.323,9.323,0,0,0-18.568,1.6A9.351,9.351,0,0,0,8.42,20.817l.158.073-1.3,2.778-.155-.075A12.382,12.382,0,1,1,22.321,5Z"
              transform="translate(0 0)"
              fill="#8a86a9"
            />
          </g>
        </svg>
      </button>
    </>
  );
}
export default function VideoPicker(el) {
  const App = <VideoPickerComponent data={JSON.parse(el.dataset.platform)} />;
  render(App, el);
}
