import clsx from "clsx";
import { Fragment } from "preact";

const PriceCard = ({
  i,
  subscriptionName,
  term,
  price,
  currencyCode,
  craft,
  plans,
  loggedin,
  hideBuyButton = false,
}) => {
  const currencySymbol = {
    EUR: "€",
    USD: "$",
    GBP: "£",
  };

  const maxPrice = (
    +plans.find(
      (plan) =>
        plan.currency == currencyCode &&
        plan.billingFrequency == "Monthly" &&
        plan.subscriptionName == subscriptionName
    ).price * 12
  ).toFixed(0);

  function trialAvailable(subscriptionName) {
    return subscriptionName == "Standard" || subscriptionName == "Expert";
  }

  const params = `?subscriptionName=${subscriptionName}&currencyCode=${currencyCode}&isMonthly=${
    term == "Monthly" ? 1 : 0
  }`;

  return (
    <div
      class={clsx(
        " border-purple  border mb-5 2xl:mb-10 xl:mb-0 xl:w-1/3 2xl:w-1/3 max-w-[600px] relative rounded-[20px] xl:rounded-[30px] 2xl:rounded-[40px]",
        craft?.popularOption &&
          "xl:mx-5 xl:min-w-[350px] 2xl:min-w-[470px] bg-orange bg-opacity-5",
        !craft?.popularOption && "xl:mt-[60px]"
      )}
    >
      {craft?.popularOption && (
        <div class="w-full bg-orange grid place-content-center rounded-t-[20px] xl:rounded-t-[30px] 2xl:rounded-t-[40px] h-[60px]">
          <p class="uppercase font-bold text-white grid">Most popular option</p>
        </div>
      )}

      <div class="grid mt-5 2xl:mt-10 mb-5 2xl:mb-20 mx-5">
        <div class="justify-between grid md:flex xl:grid">
          <div class="grid">
            {/* <p class="text-teal md:mx-5 font-bold text-[14px]">#0{i}</p> */}
            <p class="h1 2xl:mx-5 mb-3 2xl:mb-5 ">{subscriptionName}</p>
          </div>

          <div class="flex 2xl:mx-5 mb-3 2xl:mb-5 items-center">
            <div>
              <p class="text-[50px] xl:text-[60px] 2xl:text-[70px]">
                {currencySymbol[currencyCode]}
                {(+price).toFixed(0)}
              </p>
              {term == "Yearly" && (
                <p class="text-[20px]">
                  <s>
                    {currencySymbol[currencyCode]}
                    {maxPrice}
                  </s>
                </p>
              )}
            </div>
            <div class="ml-4">
              <p class="leading-3 text-[14px] mb-2">Per user</p>
              <p class="leading-3 text-[14px] mb-2">Per {term}</p>
              <p class="text-orange text-[14px] leading-3">/ Min. 3 users</p>
            </div>
          </div>
        </div>
        <hr class="border-purple" />
        <p class="my-3 2xl:my-5 2xl:mx-5 lg:min-h-[104px]">
          {craft?.shortText}
        </p>
        <hr class="border-purple" />
        <div class="grid mt-3 2xl:mt-5">
          {craft?.bullets?.map((bullet, i) => (
            <div
              key={i}
              class="flex pl-5 py-2 2xl:py-3 odd:bg-grey bg-opacity-70 items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.792"
                height="21.792"
                viewbox="0 0 21.792 21.792"
              >
                <path
                  id="Path_1740"
                  data-name="Path 1740"
                  d="M15.713,7.439a.851.851,0,0,1,0,1.2L10,14.353a.851.851,0,0,1-1.2,0L6.079,11.635a.851.851,0,0,1,1.2-1.2L9.4,12.548l5.109-5.109a.851.851,0,0,1,1.2,0M21.792,10.9A10.9,10.9,0,1,1,10.9,0a10.89,10.89,0,0,1,10.9,10.9m-1.7,0A9.193,9.193,0,1,0,10.9,20.089,9.188,9.188,0,0,0,20.089,10.9"
                  fill="#f96209"
                />
              </svg>
              <p class="ml-3">{bullet.heading}</p>
            </div>
          ))}
        </div>
        <div class="md:flex xl:flex 2xl:mx-5 mt-3 2xl:mt-5">
          {trialAvailable(subscriptionName) && !loggedin ? (
            <div>
              <a
                href={"/subscribe" + params}
                class="bg-orange w-full mr-3 2xl:mr-5 px-3 md:w-max sm:px-7 h-[45px] rounded-md text-white hover:scale-[101%] transition-all duration-200 hover:shadow-md hover:drop-shadow-md flex items-center justify-center mb-3 md:mb-5"
              >
                <span>14 Day trial</span>
              </a>
            </div>
          ) : (
            <a
              href={`/contact-us?subject=${subscriptionName} package enquiry`}
              class="bg-orange w-full mr-3 2xl:mr-5 px-3 md:w-max sm:px-7 h-[45px] rounded-md text-white hover:scale-[101%] transition-all duration-200 hover:shadow-md hover:drop-shadow-md flex items-center justify-center mb-3 md:mb-5"
            >
              <span>Contact us</span>
            </a>
          )}
          <div>
            {!hideBuyButton && (
              <>
                {!loggedin ? (
                  <a
                    href={"/subscribe" + params + "&isInTrial=0"}
                    class="bg-teal w-full px-3 md:w-max sm:px-7 h-[45px] rounded-md text-white hover:scale-[101%] transition-all duration-200 hover:shadow-md hover:drop-shadow-md flex items-center justify-center"
                  >
                    <span>Buy now</span>
                  </a>
                ) : (
                  <a
                    href={
                      "/actions/cemantica-api/default/get-paypal-link" + params
                    }
                    class="bg-teal w-full px-3 md:w-max sm:px-7 h-[45px] rounded-md text-white hover:scale-[101%] transition-all duration-200 hover:shadow-md hover:drop-shadow-md flex items-center justify-center"
                  >
                    <span>Buy now</span>
                  </a>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
