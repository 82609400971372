import "core-js/stable";
import "regenerator-runtime/runtime";
import "./app.scss";
import Form from "./js/Form";
import FormPopup from "./js/FormPopup";
import "./app.scss";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";
import Headroom from "headroom.js";
import Menu from "./js/Menu";
import Show from "./js/Show";
import orphan from "./js/orphan";
import Line from "./js/Line";
// import "./js/loadRecaptcha";
import VideoPicker from "./js/VideoPicker";
import VideoCard from "./js/VideoCard";
import VideosCard from "./js/VideosCard";
import Animations from "./js/Animations";
import Slider from "./js/Slider";
import LessonFilter from "./js/LessonFilter";
import Slider2 from "./js/Slider2";
import ResourceFilter from "./js/ResourceFilter";
import lazySizes from "lazysizes";
import Popup from "./js/Popup";
import Alert from "./js/Alert";
import Video from "./js/Video";
import PricingCards from "./js/PricingCards";
import Assessment from "./js/Assessment";
import FormLoading from "./js/FormLoading";
import CustomerReferences from "./js/customerReferences";
import loadGTMOnScroll from "./js/GTM";
import { set } from "lodash";

let actions = {};
let pageExit = {};

function registerAction(action) {
  actions[action.name] = {
    mount: action,
  };
}

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

gsap.registerPlugin(ScrollTrigger);
registerAction(Animations);

runActions();

function runActions() {
  action("VideoPicker", VideoPicker);
  action("VideoCard", VideoCard);
  action("VideosCard", VideosCard);
  action("Slider", Slider);
  action("LessonFilter", LessonFilter);
  action("Slider2", Slider2);
  action("Form", Form);
  action("ResourceFilter", ResourceFilter);
  action("Video", Video);
  action("PricingCards", PricingCards);
  action("Assessment", Assessment);
  action("Line", Line);
  action("FormLoading", FormLoading);
  action("customerReferences", CustomerReferences);
}

Animations();
orphan();

Show(document.querySelectorAll(".show-more-btn"));
Menu(document.querySelectorAll(".menu-btn"));
Popup(document.querySelectorAll(".popup-btn"));
FormPopup(document.querySelectorAll(".form-popup"));
Alert(document.querySelectorAll(".alert-btn"));
const headroomElement = document.querySelector("#main-header");
if (headroomElement) {
  const headroom = new Headroom(headroomElement, {
    offset: 20,
  });
  headroom.init();
}

// Only used in dev
if (module.hot) {
  module.hot.accept();
}

(() => {
  let mounted = false;
  let service = {
    _open: false,
    overlay: document.querySelector(".overlay"),
    open() {
      this._open = true;

      gsap.to(this.overlay, 0.3, {
        opacity: 1,
      });
      document.body.classList.add("overflow-hidden");
    },
    close() {
      this._open = false;
      document.body.classList.remove("overflow-hidden");
      gsap.to(this.overlay, 0.3, {
        opacity: 0,
        onComplete: () => {
          this.overlay.innerHTML = "";
        },
      });
    },
  };

  window.addEventListener("click", function (evt) {
    if (evt.target.classList.contains("close-modal")) {
      service.close();
    }
  });
})();

// Only used in dev
if (module.hot) {
  module.hot.accept();
}

document.querySelectorAll("[data-hover-target]").forEach((hoverEl) => {
  const target = document.querySelector(hoverEl.dataset.hoverTarget);
  hoverEl.addEventListener("mouseenter", () => {
    target.style.display = "block";
    target.classList.remove("hidden");
  });
  hoverEl.addEventListener("mouseleave", () => {
    target.style.display = "none";
  });
});

document.querySelectorAll("[data-unhover-target]").forEach((hoverEl) => {
  const target = document.querySelector(hoverEl.dataset.unhoverTarget);
  hoverEl.addEventListener("mouseenter", () => {
    target.style.display = "none";
  });
  hoverEl.addEventListener("mouseleave", () => {
    target.style.display = "block";
    target.classList.remove("hidden");
  });
});

const backdrop = document.querySelector(".backdrop");
document.querySelectorAll(".mega-menu").forEach((mm) => {
  mm.addEventListener("mouseenter", () => (backdrop.style.opacity = 1));
  mm.addEventListener("mouseleave", () => (backdrop.style.opacity = 0));
});

(function () {
  const passwordConfirmForm = document.querySelector(".password-confirm-form");
  if (passwordConfirmForm) {
    const password = passwordConfirmForm.querySelector("#password");
    const passwordConfirm =
      passwordConfirmForm.querySelector("#passwordConfirm");
    const error = passwordConfirmForm.querySelector(".password-confirm-error");
    const submit = passwordConfirmForm.querySelector("button[type='submit']");

    [password, passwordConfirm].forEach((inp) =>
      inp.addEventListener("input", onChange)
    );

    function onChange(e) {
      if (password.value != passwordConfirm.value) {
        error.innerText = "Passwords do not match!";
        submit.disabled = true;
      } else {
        error.innerText = "";
        submit.disabled = false;
      }
    }
  }

  document.querySelectorAll(".boolean-checkbox").forEach((el) => {
    el.addEventListener("change", () => (el.value = el.checked ? "1" : "0"));
  });
})();

// document.addEventListener("DOMContentLoaded", function () {
//   var node;
//   while ((node = textNodes.nextNode())) {
//     node.nodeValue = node.nodeValue.replace(/\u2028/g, "");
//   }
// });

// 6LfcRxkaAAAAAOweFe0e0iTwVtKFsvmiLVsL9dBZ
// grecaptcha.ready(function () {
//   grecaptcha
//     .execute("6LfcRxkaAAAAAOweFe0e0iTwVtKFsvmiLVsL9dBZ", { action: "submit" })
//     .then(function (token) {
//       //Call to the Cemantica Web API (with token value in the 'ReCaptchaToken' attribute);
//       document
//         .querySelectorAll(".reCaptchaToken")
//         .forEach(
//           (el) =>
//             (el.outerHTML = `<input name="reCaptchaToken" value="${token}" />`)
//         );
//     });
// });

const alertDiv = document.getElementById("alert-div");
if (alertDiv) {
  // Check if the div has already been shown
  if (!localStorage.getItem("divShown")) {
    // Show the div
    alertDiv.style.display = "block";
    // Set a flag in local storage to indicate that the div has been shown
    localStorage.setItem("divShown", "true");
  }
}

var dropdown = document.getElementById("jobDropdown");
var hiddenField = document.getElementById("subject-field");

// Add change event listener to the dropdown
dropdown?.addEventListener("change", function () {
  // Get the selected value from the dropdown
  var selectedValue = dropdown.value;

  // Set the value of the hidden input field
  hiddenField.value = selectedValue;
});

// Attach the scroll event listener
window.addEventListener("scroll", loadGTMOnScroll);
