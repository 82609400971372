import clsx from "clsx";

const VidButton = ({
  children,
  active,
  onClick,
  green,
  icon,
  className,
  i,
}) => {
  let style = "";

  switch (i % 5) {
    case 0:
    case 5:
      style +=
        "box-shadow: 0px 0px 10px 0px #3F3D564C; color: #3F3D56 !important;";
      break;
    case 1:
      style +=
        "box-shadow: 0px 0px 10px 0px #F962094C; color: #F96209 !important;";
      break;
    case 2:
      style +=
        "box-shadow: 0px 0px 10px 0px #18AEAD4C; color: #18AEAD !important;";
      break;
    case 3:
      style +=
        "box-shadow: 0px 0px 10px 0px #20A9E94C; color: #20A9E9 !important;";
      break;
    case 4:
      style +=
        "box-shadow: 0px 0px 10px 0px #FDD03E4C; color: #F6BD02 !important;";
      break;
    default:
      break;
  }

  return (
    <button
      onClick={onClick}
      style={style}
      class={clsx(
        " button px-2 lg:px-4 xl:px-7 py-3 nav-left mb-1 lg:rounded-2xl items-center cursor-pointer w-full transition-all",
        active && "border-current border transform scale-105",
        className
      )}
    >
      {icon && (
        <img
          src={icon}
          class="hidden lg:block h-10 object-contain mx-auto mb-5"
          alt="icon"
        />
      )}

      <div
        class={clsx(
          " transition-all duration-100 min-h-[30px] rounded-full map-nav-btn grid",
          active && ""
        )}
      >
        <p class="mb-0 text-[14px] font-bold text-center leading-[1.1em] ">
          {children}
        </p>
      </div>
    </button>
  );
};

export default VidButton;
