import getcaptcha from "./utils/getcaptcha";

var _globalScoreConclusions = [
  "Little to no evidence of a focus on customer experience", // 0 to 20
  "Approach to CX is immature immediate action is required", // 20 to 35
  "Evidence of a focus on CX evident but it is not structured or sustainable", // 35 to 45
  "An intentional approach to CX has been established, but not yet systematic", // 45 to 60
  "Some, but not all, CX competencies are being managed systematically", // 60 to 80
  "A systematic approach to CX is evident and sustainable", // 80 to 100
];

export default function Assessment(el) {
  const startForm = el.querySelector("form");
  const testForm = el.querySelector(".cxq-container");
  const testSubmit = el.querySelector(".cxq-container button[type='submit']");
  const scoreForm = el.querySelector(".assm-score");
  const score = {};
  let leadId = "";
  let csrf = "";

  startForm.addEventListener("submit", (e) => {
    e.preventDefault();

    const startFormData = new FormData();

    [...startForm.querySelectorAll("input")].forEach((inp) =>
      startFormData.append(inp.name, inp.value)
    );

    startFormData.delete("confirm");
    const submit = startForm.querySelector('[type="submit"]');
    submit.disabled = true;
    submit.innerHTML = "<span>Please wait...</span>";

    getcaptcha().then((token) => {
      startFormData.append("reCaptchaToken", token);
      fetch(
        location.origin + "/actions/cemantica-api/default/cx-assessment-lead",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: startFormData,
        }
      )
        .then((r) => r.json())
        .then((r) => {
          leadId = r.leadId;
          csrf = r.csrf;

          startForm.style.display = "none";
          testForm.style.display = "block";
        })
        .catch((e) => {
          console.log(e);
        });
    });

    collectScore();
  });

  document.querySelectorAll(".ratebar").forEach((rb) => {
    rb.querySelectorAll("li").forEach((li, i, allLi) =>
      li.addEventListener("click", () => {
        allLi.forEach((li) => li.classList.remove("selected"));
        li.classList.add("selected");
        collectScore();
      })
    );
  });

  function collectScore() {
    document.querySelectorAll(".ratebar li.selected").forEach((li) => {
      const name = li.parentElement.id;
      score[name] = parseInt(li.innerHTML);
      console.log(score);
    });
  }

  testSubmit.addEventListener("click", (e) => {
    testForm.style.display = "none";
    scoreForm.style.display = "block";

    const scoreFormData = new FormData();

    Object.keys(score).forEach((key) => scoreFormData.append(key, score[key]));
    scoreFormData.append("leadId", leadId);
    scoreFormData.append("CRAFT_CSRF_TOKEN", csrf);

    fetch(
      location.origin + "/actions/cemantica-api/default/cx-assessment-summary",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: scoreFormData,
      }
    )
      .then((r) => r.json())
      .then((r) => {
        console.log(r);
      })
      .catch((e) => {
        console.log(e);
      });

    const calculatedScore = Object.values(score).reduce((a, b) => a + b);

    document.getElementById("CxAssessmentGlobalScore").style =
      "width:" + calculatedScore + "%;";
    document.getElementById("calculated-score").innerHTML = calculatedScore;
    var conclusion = "";
    if (calculatedScore > 80 && calculatedScore <= 100)
      conclusion = _globalScoreConclusions[5];
    else if (calculatedScore > 60 && calculatedScore <= 80)
      conclusion = _globalScoreConclusions[4];
    else if (calculatedScore > 45 && calculatedScore <= 60)
      conclusion = _globalScoreConclusions[3];
    else if (calculatedScore > 35 && calculatedScore <= 45)
      conclusion = _globalScoreConclusions[2];
    else if (calculatedScore > 20 && calculatedScore <= 35)
      conclusion = _globalScoreConclusions[1];
    else if (calculatedScore >= 0 && calculatedScore <= 20)
      conclusion = _globalScoreConclusions[0];
    document.getElementById("CxAssessmentGlobalScoreConclusion").innerHTML =
      conclusion;
    //   $(".assm-score").show();
    document.querySelector(".assm-score").style.display = "block";
    location.href = "#myscore";
  });
}
